<template>
    <div v-if="word" class="product">
        <div class="product-title">
            <img src="./../assets/voice.png" alt="">
            <div class="banner-text">{{word.product.voice[language]}}</div>
        </div>
        <div class="product-info animate__animated animate__fadeInUp">
            <div class="product-item">
                <div>
                    <div class="product-subtitle">{{word.voice.voice_main_1[language]}}</div>
                    <div>{{word.voice.voice_sub_1[language]}}</div>
                </div>
                <img src="./../assets/product/voice1.png" alt="">
            </div>
            <div class="product-item">
                <img src="./../assets/product/voice4.png" alt="">
                <div>
                    <div class="product-subtitle">{{word.voice.voice_main_2[language]}}</div>
                    <div>{{word.voice.voice_sub_2[language]}}</div>
                </div>
            </div>
            <div class="product-item">
                <div>
                    <div class="product-subtitle">{{word.voice.voice_main_3[language]}}</div>
                    <div>{{word.voice.voice_sub_3[language]}}</div>
                </div>
                <img src="./../assets/product/voice3.png" alt="">
            </div>
        </div>
        <div>
            <div style="text-align: center;font-size: 30px;margin-top: 150px;">{{word.product.tip[language]}}</div>
            <div @click="toPrice" class="look-price">{{word.product.buttonText[language]}}</div>
        </div>
    </div>
</template>

<script>
import 'animate.css';
import word from './../assets/json/language.json'
export default {
    name: 'Product',
    components: {},
    data() {
        return {
            language: '',
            word: word,
        }
    },
    created() {
        this.language = localStorage.getItem('language')
    },
    methods: {
        toPrice() {
            this.$router.push({path: '/price'})
        }
    }
}
</script>
<style lang="scss" scoped>
    .product-title {
        width: 100%;
        height: 300px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 100px;
        background-color: #6C63FF;
        img {
            display: block;
            height: 300px;
            text-align: center;
        }
        .banner-text {
            position: absolute;
            left: 30%;
            top: 40%;
            font-size: 46px;
            color: #fff;
        }
    }
    .product-info {
        .product-item {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            img {
                width: 400px;
                flex-shrink: 0;
            }
            >div {
                width: 400px;
                padding: 0 50px;
                color: #888;
                .product-subtitle {
                    font-size: 30px;
                    margin-bottom: 10px;
                    color: #353535;
                }
            }
        }
    }
</style>
